.power-btn {
  filter: drop-shadow(0px 0px 2px rgba(223, 13, 13, 0.534));
  transition: all 0.2s ease-in-out;
}


.power-btn:hover {
  filter: drop-shadow(0px 0px 2px rgb(235, 2, 2));

}


/* USER LIST STYLES */
#user-list th {
  background-color: white;
  color: gray;
}

#user-list td {
  text-align: start;
}

.ant-upload {
  width: 100%
}


.button-container {
  /* position: fixed; */
  padding: 10px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}


.button-text-container {
  order: -1;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  /*Keep text always one line*/
  overflow: hidden;
  width: 0;
  transition: width 1s;
}

.button-icon-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button-icon-container:hover + .button-text-container {
  width:200px;
}

.button-text-container:hover{
     width:200px;
}