/* ./src/index.css */
@import "~antd/dist/antd.css";
@tailwind base;
@tailwind components;
@tailwind utilities;







/* Works on Firefox */
* {
	scrollbar-width: thin;
	scrollbar-color: #9092f1 #d1d5db;
}

/* https://www.w3generator.com/scrollbar */
::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}

::-webkit-scrollbar-thumb {
	background: #9092f1;
	border-radius: 9px;
}

::-webkit-scrollbar-thumb:hover {
	background: #4a4cce;
}

::-webkit-scrollbar-track {
	background: #ffffff;
	border-radius: 7px;
	box-shadow: inset 7px 10px 12px #f0f0f0;
}

.gradient-blue {
	background: linear-gradient(180deg, #89e5fe 0%, #46c6eb 100%);
	box-shadow: 6px 5px 16px -8px rgb(74 74 74 / 75%);
}

.ant-tabs-ink-bar {
	height: 8px;
	background: #3133da !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: #3133da;
}

.ant-tabs-tab {
	min-width: 60px;
}

.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
	color: #4a4ddd;
}

.ant-menu-item {
	margin: 12px;
	border-radius: 6px;
	background-color: white;
}

.ant-menu-item a {
	color: black;
}

.ant-menu-item .ant-menu-item-selected {
	background-color: #543dd3;
}

.ant-menu-item .ant-menu-item-selected a {
	color: white;
}

.ant-menu-item.ant-menu-item-selected .anticon.ant-menu-item-icon svg {
	color: white !important;
}

.ant-menu-inline .ant-menu-item::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
	content: "";
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
	background-color: #3133da;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a {
	color: white;
}

.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
	width: auto;
}

.site-layout .site-layout-background {
	background: #fff;
}

.ant-notification-notice {
	border-radius: 8px;
}

.access-log table th {
	background-color: #4a4cce;
	color: white;
	text-align: center;
}

table tbody tr td {
	text-align: center;
}

.site-description-item-profile-wrapper {
	margin-bottom: 7px;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	line-height: 1.5715;
}

[data-theme="compact"] .site-description-item-profile-wrapper {
	font-size: 12px;
	line-height: 1.66667;
}

.ant-drawer-body p.site-description-item-profile-p {
	display: block;
	margin-bottom: 16px;
	color: rgba(0, 0, 0, 0.85);
	font-size: 16px;
	line-height: 1.5715;
}

[data-theme="compact"] .ant-drawer-body p.site-description-item-profile-p {
	font-size: 14px;
	line-height: 1.66667;
}

.site-description-item-profile-p-label {
	display: inline-block;
	margin-right: 8px;
	color: rgba(0, 0, 0, 0.85);
}

.gradient-bg {
	background: linear-gradient(285deg, #faf4f9, #f3f8fc 80%);
}

#logs .ant-table-thead .ant-table-filter-trigger-container .active {
	color: white;
}

#logs .ant-table-thead .ant-table-filter-trigger-container:hover,
#logs .ant-table-thead .ant-table-filter-trigger-container:hover .ant-dropdown-open {
	background-color: #3b3cac;
}


progress {
	width: 100%;
	height: 5px;
	@apply bg-gray-300 rounded-md;
}

progress::-webkit-progress-bar {
	width: 100%;
	height: 5px;
	@apply bg-gray-300 rounded-md;
}

progress::-webkit-progress-value {
	@apply bg-indigo-500 rounded-md;
}

progress::-moz-progress-bar {
	width: 100%;
	height: 5px;
	@apply bg-gray-300 rounded-md;
}